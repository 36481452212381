import AuthAPIs from './Auth';
import ReviewsAPIs from './Reviews';

import getConfigForEnvironment from '../../config/getConfigForEnvironment';

const AuthAPIsSingleton = new AuthAPIs(getConfigForEnvironment());
const ReviewsAPIsSingleton = new ReviewsAPIs(getConfigForEnvironment());

export {
  AuthAPIsSingleton,
  ReviewsAPIsSingleton,
};

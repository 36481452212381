import React, { createContext, useContext, useReducer } from 'react';

import PropTypes from 'prop-types';

const BrandStateContext = createContext();
const BrandDispatchContext = createContext();

function brandReducer(state, action) {
  switch (action.type) {
    case 'SET_QUESTIONNAIRE_INFOS':
      return {
        ...state,
        status: action.status,
        theme: action.theme,
        area: action.area,
        item: action.item,
        itemId: action.itemId,
        areaId: action.areaId,
        themeId: action.themeId,
        renderStrategy: action.renderStrategy,
        filterByQuestions: action.filterByQuestions,
        filterResults: action.filterResults,
        submissionDate: action.submissionDate,
        typeOfReview: action.typeOfReview,
        source: action.source,
        menuOpen: action.menuOpen,
        areaSlug: action.areaSlug,
        themeSlug: action.themeSlug,
        isReRateQuickReview: action.isReRateQuickReview,
      };
    case 'TOGGLE_MENU':
      return {
        ...state,
        menuOpen: !state.menuOpen,
      };
  
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function BrandProvider({ children }) {
  const [state, dispatch] = useReducer(brandReducer, {
    status: 'not-started',
    theme: '',
    area: '',
    item: '',
    itemId: '',
    areaId: '',
    themeId: '',
    submissionDate: '',
    filterByQuestions: 'all-questions',
    filterResults: 'All Results',
    typeOfReview: 're-rate',
    source: '',
    menuOpen: true,
    areaSlug: '',
    themeSlug: '',
    renderStrategy: 'editable',
    isReRateQuickReview: false,
  });
  

  return (
    <BrandStateContext.Provider value={state}>
      <BrandDispatchContext.Provider value={dispatch}>
        {children}
      </BrandDispatchContext.Provider>
    </BrandStateContext.Provider>
  );
}

function useBrandState() {
  const context = useContext(BrandStateContext);
  if (context === undefined) {
    throw new Error('useBrandState must be used within a BrandProvider');
  }
  return context;
}

function useBrandDispatch() {
  const context = useContext(BrandDispatchContext);
  if (context === undefined) {
    throw new Error('useBrandDispatch must be used within a BrandProvider');
  }
  return context;
}

function dispatchQuestionnaireInfos(dispatch, questionnaireInfos) {
  dispatch({ type: 'SET_QUESTIONNAIRE_INFOS', ...questionnaireInfos });
}

function dispatchToggleMenu(dispatch, state) {
  dispatch({ type: 'TOGGLE_MENU', ...state });
}
BrandProvider.propTypes = {
  children: PropTypes.shape({}).isRequired,
};

export { BrandProvider, useBrandState, useBrandDispatch, dispatchQuestionnaireInfos, dispatchToggleMenu };

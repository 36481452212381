import React, { createContext, useContext, useReducer } from 'react';

import { AuthAPIsSingleton } from '../modules/api';
import PropTypes from 'prop-types';

const UserStateContext = createContext();
const UserDispatchContext = createContext();

function userReducer(state, action) {
  switch (action.type) {
    case 'LOGIN_SUCCESS':
      return {
        ...state,
        isAuthenticated: true,
        accessToken: action.accessToken,
        user: action.data.name,
        userId: action.data.id,
      };
    case 'SIGN_OUT_SUCCESS':
      return { ...state, isAuthenticated: false, user: null };
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function UserProvider({ children }) {
  const [state, dispatch] = useReducer(userReducer, {
    isAuthenticated: !!localStorage.getItem('accessToken'),
    accessToken: localStorage.getItem('accessToken'),
    user: localStorage.getItem('userEmail'),
    userId: JSON.parse(localStorage.getItem('userId')),
  });

  return (
    <UserStateContext.Provider value={state}>
      <UserDispatchContext.Provider value={dispatch}>
        {children}
      </UserDispatchContext.Provider>
    </UserStateContext.Provider>
  );
}

function useUserState() {
  const context = useContext(UserStateContext);
  if (context === undefined) {
    throw new Error('useUserState must be used within a UserProvider');
  }
  return context;
}

function useUserDispatch() {
  const context = useContext(UserDispatchContext);
  if (context === undefined) {
    throw new Error('useUserDispatch must be used within a UserProvider');
  }
  return context;
}

function loginUser(dispatch, login, password, navigate, from, setIsLoading, setError, toastFn) {
  setError(false);
  setIsLoading(true);

  if (!!login && !!password) {
    AuthAPIsSingleton.login(login, password)
      .then((r) => {
        const { data } = r;
        const { token } = data;
        localStorage.setItem('accessToken', token);
        localStorage.setItem('user', JSON.stringify(data.name));
        localStorage.setItem('userId', JSON.stringify(data.id));
        setError(null);
        setIsLoading(false);
        dispatch({ type: 'LOGIN_SUCCESS', token, data });
        navigate(from, { replace: true });
      })
      .catch((e) => {
        toastFn(e.message, { variant: 'error' });
        setError(true);
        setIsLoading(false);
      });
  } else {
    setError(true);
    setIsLoading(false);
  }
}

function resetPassword(email, setError, setIsLoading, setSuccess) {
  setError(false);
  setIsLoading(true);

  if (email) {
    AuthAPIsSingleton.resetPassword(email)
      .then(() => {
        setError(null);
        setIsLoading(false);
        setSuccess(true);
      }).catch(() => {
        setError(true);
        setIsLoading(false);
      });
  } else {
    setError(true);
    setIsLoading(false);
  }
}

function signOut(dispatch, history) {
  localStorage.removeItem('accessToken');
  localStorage.removeItem('user');
  dispatch({ type: 'SIGN_OUT_SUCCESS' });
  if (history) {
    history.push('/login');
  }
}

UserProvider.propTypes = {
  children: PropTypes.shape({}).isRequired,
};
export {
  UserProvider, useUserState, useUserDispatch, loginUser, signOut, resetPassword,
};

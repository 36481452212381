import { createTheme, ThemeOptions } from '@mui/material/styles';
import { TypographyOptions } from '@mui/material/styles/createTypography';
import defaultTheme, { Theme as DefaultThemeInternal } from './default';

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    subtitle3: true;
    helperText: true;
  }
}

interface ExtendedTypographyOptions extends TypographyOptions {
  subtitle3: React.CSSProperties;
  helperText: React.CSSProperties;
}

const materialBaseTheme = (theme: DefaultThemeInternal): ThemeOptions =>
  ({
    ...theme,
    typography: {
      fontFamily: 'Roboto, sans-serif',
      fontWeightLight: '200',
      fontWeightRegular: '400',
      fontWeightMedium: '500',
      fontWeightBold: '700',
      h1: {
        fontSize: '48px',
        fontWeight: 400,
        lineHeight: '56px',
        letterSpacing: '0px',
      },
      h2: {
        fontSize: '34px',
        fontWeight: 400,
        lineHeight: '34px',
        letterSpacing: '0.25px',
      },
      h3: {
        fontSize: '24px',
        fontWeight: 400,
        lineHeight: '32px',
        letterSpacing: '0px',
      },
      h4: {
        fontSize: '20px',
        fontWeight: 500,
        lineHeight: '32px',
        letterSpacing: '0.15px',
      },
      subtitle1: {
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: '28px',
        letterSpacing: '0.15px',
      },
      subtitle2: {
        fontSize: '14px',
        fontWeight: 500,
        lineHeight: '22px',
        letterSpacing: '0.1px',
      },
      subtitle3: {
        fontSize: '12px',
        fontWeight: 400,
        lineHeight: '32px',
        letterSpacing: '1px',
      },
      body1: {
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: '24px',
        letterSpacing: '0.15px',
      },
      body2: {
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '20px',
        letterSpacing: '0.17px',
      },
      button: {
        fontWeight: 500,
        fontSize: '15px',
      },
      caption: {
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '20px',
        letterSpacing: '0.17px',
        color: defaultTheme.palette.text.secondary,
      },
      overline: {
        fontSize: '12px',
        fontWeight: 400,
        lineHeight: '20px',
        letterSpacing: '1px',
        textTransform: 'uppercase',
      },
      helperText: {
        fontSize: '12px',
        fontWeight: 400,
        letterSpacing: '0.17px',
        lineHeight: '20px',
      },
    } as ExtendedTypographyOptions,
  } as ThemeOptions);

export default {
  default: createTheme(materialBaseTheme(defaultTheme)),
};

declare module '@mui/styles' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends DefaultThemeInternal {
    typography: ExtendedTypographyOptions;
  }
}

import { Auth0Provider } from '@auth0/auth0-react';

const Auth0ProviderWithHistory = ({ children }) => {
  const domain = process.env.REACT_APP_AUTH0_DOMAIN || '';
  const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID || '';
  const redirectUri = process.env.REACT_APP_AUTH0_REDIRECT_URI || '';

  const onRedirectCallback = async (appState) => {
    try {
      window.history.replaceState(
        {},
        document.title,
        appState?.returnTo || window.location.pathname,
      );
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      redirectUri={redirectUri}
      onRedirectCallback={onRedirectCallback}
      cacheLocation='localstorage'
      useRefreshTokens={true}
    >
      {children}
    </Auth0Provider>
  );
};

export default Auth0ProviderWithHistory;

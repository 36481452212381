/* eslint-disable no-console */
import axios from 'axios';
export default class Api {
  constructor(config) {
    this.config = config;
    this.instance = axios.create({
      baseURL: process.env.REACT_APP_PUBLIC_API_URL,
      timeout: 1200000,
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
      },
    });
    this.instance.interceptors.response.use(
      (response) => response,
    );
  }

  static addAuthorizationHeader(authToken) {
    return { headers: { Authorization: `Bearer ${authToken}` } };
  }
}

import React, { createContext, useContext, useReducer } from 'react';

const AreasProgressionContext: React.Context<any> = createContext({});
const AreasProgressionDispatchContext: React.Context<any> = createContext({});

function AreasProgressionReducer(state: any, action: any) {
  switch (action.type) {
    case 'UPDATE_AREAS_PROGRESSION':
      return { ...state, ...action.payload };
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
}

function AreasProgressionProvider({ children }: any) {
  const [state, dispatch] = useReducer(AreasProgressionReducer, {});

  return (
    <AreasProgressionContext.Provider value={state}>
      <AreasProgressionDispatchContext.Provider value={dispatch}>
        {children}
      </AreasProgressionDispatchContext.Provider>
    </AreasProgressionContext.Provider>
  );
}

function useAreasProgressionState() {
  const context = useContext(AreasProgressionContext);
  if (context === undefined) {
    throw new Error(
      'useAreasProgressionState must be used within a AreasProgressionProvider',
    );
  }
  return context;
}

function useAreasProgressionDispatch() {
  const context = useContext(AreasProgressionDispatchContext);
  if (context === undefined) {
    throw new Error(
      'useAreasProgressionDispatch must be used within a AreasProgressionProvider',
    );
  }
  return context;
}

function dispatchUpdateAreasProgression(dispatch: any, payload: any) {
  dispatch({
    type: 'UPDATE_AREAS_PROGRESSION',
    payload,
  });
}

export {
  AreasProgressionProvider,
  useAreasProgressionState,
  useAreasProgressionDispatch,
  dispatchUpdateAreasProgression,
};

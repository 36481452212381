import { blue, green, grey, lightBlue, red } from '@mui/material/colors';

import tinycolor from 'tinycolor2';

const primary = blue[700]; // '#1976D2';
const secondary = '#222';
const warning = '#ED6C02';
const success = green[500]; // '#4CAF50';
const error = red[700]; // '#D32F2F';
const info = lightBlue[700]; // #0288D1';

// Deprecation soon: The use of gradients are not recommended with @mui palette.
const infoDarker =
  'linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),linear-gradient(0deg, #0288D1, #0288D1)';
const dangerDarker =
  'linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),linear-gradient(0deg, #D32F2F, #D32F2F)';

// buttons prim
const btnBGPrimary = '#1B1B1B';
const btnTXTPrimary = '#FFFFFF';
const btnBGPrimaryHover = '#1B1B1B';
// buttons secondary
const btnBGSecondary = grey[300]; // #E0E0E0
const btnTXTSecondary = '#1B1B1B';
const btnBGSecondaryHover = grey[100]; // #F5F5F5
// buttons disabled
const btnBgDisabled = 'rgba(0, 0, 0, 0.12)';
const btnTxTDisabled = 'rgba(0, 0, 0, 0.26)';

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    primary: true;
    secondary: true;
  }
}

export const colors = {
  primary,
  secondary,
  warning,
  success,
  error,
  info,
  infoDarker,
  dangerDarker,
  btnBGPrimary,
  btnTXTPrimary,
  btnBGPrimaryHover,
  btnBGSecondary,
  btnTXTSecondary,
  btnBGSecondaryHover,
  btnBgDisabled,
  btnTxTDisabled,
};

const componentsVariants = {
  MuiButton: {
    variants: [
      {
        props: { variant: 'primary' },
        style: {
          color: btnTXTPrimary,
          backgroundColor: btnBGPrimary,
          '&:hover': {
            backgroundColor: btnBGPrimaryHover,
          },
          '&:disabled': {
            color: btnTxTDisabled,
            backgroundColor: btnBgDisabled,
          },
        },
      },
      {
        props: { variant: 'secondary' },
        style: {
          color: btnTXTSecondary,
          backgroundColor: btnBGSecondary,
          '&:hover': {
            backgroundColor: btnBGSecondaryHover,
          },
          '&:disabled': {
            color: btnTxTDisabled,
            backgroundColor: btnBgDisabled,
          },
        },
      },
    ],
  },
  MuiAlert: {
    variants: [
      {
        props: { variant: 'grey' },
        style: {
          color: grey[600],
          backgroundColor: grey[200],
        },
      },
    ],
  },
};

export const theme = {
  palette: {
    primary: {
      main: primary,
      light: tinycolor(primary).toHexString(),
      dark: tinycolor(primary).toHexString(),
      lighter: tinycolor(primary).toHexString(),
    },
    secondary: {
      main: secondary,
      light: tinycolor(secondary).toHexString(),
      dark: tinycolor(secondary).toHexString(),
      contrastText: '#FFFFFF',
    },
    warning: {
      main: warning,
      light: '#ED6C02',
      dark: tinycolor(warning).toHexString(),
      darker: tinycolor(warning).toHexString(),
    },
    success: {
      main: success,
      light: green[800], // '#4caf50',
      dark: green[900], // '#1B5E20',
      darker: green[900],
    },
    error: {
      main: error,
      light: red[700], // '#D32F2F',
      dark: red[700], // '#D32F2F',
      darker: dangerDarker,
    },
    info: {
      main: info,
      light: lightBlue[500], // '#03a9f4',
      dark: lightBlue[900], // '#01579B',
      darker: infoDarker,
    },
    text: {
      primary: 'rgba(0, 0, 0, 0.87)',
      secondary: 'rgba(0, 0, 0, 0.6)',
      disabled: 'rgba(0, 0, 0, 0.38)',
    },
    grey: {
      light: grey[200],
      main: grey[600],
      dark: 'rgba(0, 0, 0, 0.6)',
      lighter: 'rgba(0, 0, 0, 0.26)',
    },
    // Todo: tHIS LOOKS LIKE A TYPO
    // placeholder: '#9DA2B978',
    background: {
      default: 'rgba(255, 255, 255, 0.002)',
      light: '#FFFFFF',
    },
    greyAlt: {
      '04': 'rgba(0, 0, 0, 0.04)',
    },
  },
  customShadows: {
    none: 'none',
    xs: '0px 4px 8px rgba(0,0,0,0.08)',
    sm: '0px 6px 12px rgba(0,0,0,0.11)',
    md: '0px 9px 18px rgba(0,0,0,0.15)',
    lg: '0px 13px 37px rgba(0,0,0,0.21)',
    xl: '0px 20px 56px rgba(0,0,0,0.29)',
  },
  components: componentsVariants,
  overrides: {},
};

export type Theme = typeof theme;
export default theme;
